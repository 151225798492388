import fetch from 'unfetch'
import * as Sentry from '@sentry/browser'

let sentry_options = null

if (typeof sessionStorage !== 'undefined') {
    try {
        sentry_options = JSON.parse(sessionStorage.getItem('sentry_options'))
    } catch(e) {}
}

if (sentry_options) {
    if (typeof sentry_options === 'object' && sentry_options.hasOwnProperty('dsn')) {
        Sentry.init(sentry_options)
    }
} else {
    fetch("/sentry.php?channel=frontend_jquery").then(res => {
        if (res.ok) {
            res.json().then(data => {
                sentry_options = data

                if (typeof sessionStorage !== 'undefined') {
                    sessionStorage.setItem('sentry_options', JSON.stringify(sentry_options))
                }

                if (typeof sentry_options === 'object' && sentry_options.hasOwnProperty('dsn')) {
                    Sentry.init(sentry_options)
                }
            })
        }
    })
}
