import './include/sentry'

import 'script-loader!./library/jquery-1.12.4.min.js'
import 'script-loader!./library/jquery-migrate-1.2.1.js'
import 'script-loader!./library/jquery-ui-1.13.2.min.js'
import 'script-loader!./library/jquery.placeholder.fix.js'
import 'script-loader!./library/jquery.validate.min.js'
import 'script-loader!./library/jsviews.0.9.82.js'

import 'script-loader!././library/globalize.js'
import 'script-loader!./include/Legacy.js'
import 'script-loader!./jquery.i18n.js'

import 'script-loader!./include/no-cookies.js'

import './include/jsviews.helpers-converters'

import {API} from './include/API/API.js'
import {PasswordValidator} from "./include/PasswordValidator";
import DOMPurify from "dompurify";

window.API = API;

if (window.location.pathname.indexOf("newpassword-complete.php") === -1 &&
    window.location.pathname.indexOf("password-expired.php") === -1) {
    //Fragment ermitteln
    var fragment = location.hash;

    if ((fragment.length > 0) && (fragment.substring(0, 1) != '#')) {
        // Sicherstellen, dass # vor dem Fragment steht
        fragment = '#' + fragment;
    }


    // aktuelle Post-URL holen
    var url = $('form').attr('action');

    // Fragment mit an die URL hängen
    $('form').attr('action', url + fragment);

    $('#fragment').val(fragment);
}

$(document).ready(function () {

    if (window.location.pathname.indexOf("newpassword-complete.php") !== -1 ||
        window.location.pathname.indexOf("password-expired.php") !== -1) {
        var page_settings = {
            "demo": false,
            "locale": "en_US",
            "PasswordValidator": {
                Policy: {
                    Length: 6,
                    Digits: 1,
                    UpperCase: 1,
                    LowerCase: 0,
                    SpecialChars: 1,
                    RepetitiveChars: 0
                },
                "UserID": null,
                "EMail": null,
                Fields: {
                    PolicyDescription: $(".password-strength"),
                    Errors: $(".server-errors")
                }
            },
        };

        var changePasswordField = function (element, type) {
            var newElem = element.clone();
            newElem.attr('type', type);
            newElem.insertBefore(element);
            element.remove();
        };

        try {
            page_settings = $.extend(true, page_settings, JSON.parse($("#page_settings").text()));
        } catch (e) {
            console.log("oops");
        }

        API.Demo = page_settings.demo;
        $.i18n.init(page_settings.locale, null);

        var validate_form = $('form').validate({
            showErrors: function (errorMap, errorList) {
                let password_error = $(".password-error");
                // if (errorList.length > 0) {
                //   $("[name=passwordNew]").popover("hide");
                // }

                for (let error of errorList) {
                    if (error.message.hasOwnProperty('server_error')) {
                        password_error.append(error.message.server_error.map((e) => `<span>${e}</span>`).join(" "));
                        password_error.fadeIn();
                        // $(er)
                        // $(error.element).tooltip({
                        //   // container: 'form',
                        //   tooltipClass: "server-error",
                        //   content: error.message.server_error.map((e) => `<span>${e}</span>`).join(" ")
                        //   // html: true
                        // })
                    } else if (error.message.hasOwnProperty('server_error_clear')) {
                        password_error.html("");
                        password_error.fadeOut();
                    } else {
                        this.defaultShowErrors();
                    }
                }
                // $(error.element).tooltip('open');
            },
            highlight: function (element, errorClass, validClass) {
                $(element).addClass(errorClass).removeClass(validClass);
                $(element).parents('.dropdown').addClass(errorClass).removeClass(validClass);
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass(errorClass).addClass(validClass);
                $(element).parents('.dropdown').removeClass(errorClass).addClass(validClass);
            },
            errorPlacement: function (error, element) {
                element.attr('title', error.text());
            },
            rules: {
                passwordCurrent: 'required',
                passwordVerify: {
                    equalTo: '#password-new'
                },
            }
        });

        $('#login #form').EnablePlaceholder();

        $('.show-password').on('mousedown', function () {
            changePasswordField($(this).siblings('input'), 'text');
        });

        $('.show-password').on('mouseup', function () {
            changePasswordField($(this).siblings('input'), 'password');
        });

        var passwordValidator = new PasswordValidator($('#password-new'), page_settings.PasswordValidator, validate_form);
    }

    // When the user exits the "username" field, check to see if it's an oauth domain
    $("#username").on('blur', function (e) {
        processUsernameEvent(e);
    });
    $("#username").on('keydown', function (e) {
        //alert(e.key);
        if (e.key === 'Enter' || e.keyCode === 13) {
            processUsernameEvent(e);
        }
    });

    loadAlert();
});

function processUsernameEvent(event) {
    // Prevent regular form submission if the user hits enter
    event.preventDefault();
    event.stopPropagation();

    var username = $("#username").val().trim();
    if (username === "" || username.indexOf("@") === -1 || username.indexOf(".") === -1) {
        $('fieldset').addClass('state-initial');
        $('#username').addClass('state-initial');
        $('#username-icon').addClass('state-initial');
        $('#password').addClass('state-initial');
        $('#button').addClass('state-initial');
        return;
    }

    // If the TLD is not at least 2 chars long, exist since it can't be a valid TLD
    var dotRevIdx = username.split("").reverse().join("").indexOf(".");
    if (dotRevIdx < 2) {
        return;
    }

    // Need to set headers here since API can't be properly initialized for non-logged in users
    var header = {'X-Api-Version': 1.0};
    API.Post('/API/AuthType', {'email': username}, {'headers': header}).done((data) => {
        if (data.url !== null && data.url !== '') {
            window.location.href = data.url;
        } else {
            $('fieldset').removeClass('state-initial');
            $('#username').removeClass('state-initial');
            $('#username-icon').removeClass('state-initial');
            $('#password').removeClass('state-initial');
            $('#button').removeClass('state-initial');
            $('#password').focus();
        }
    });
}

function loadAlert() {
    API.Get('/API/Alert/login', { headers: { 'X-Api-Version': 1.0, 'Accept': 'text/html' }}).done(data => {
        if (typeof data !== 'undefined' && data.type !== null && data.message !== null && data.message.length > 0) {
            const message = DOMPurify.sanitize(data.message);
            $('#login-alert')
                .html(message)
                .addClass(data.type)
                .show('blind');
        }
    });
}

jQuery.htmlPrefilter = function (html) {
    return html;
};
